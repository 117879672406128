import {breakpoint} from 'common/theme';
import {SanityReviewSliderData} from 'common/types';
import {motion} from 'framer-motion';
import {FC, useState} from 'react';
import styled from 'styled-components';

const Wrapper = styled.section<{reverse?: boolean}>`
  margin-top: ${({theme}) => theme.spacing(2 + 1.75 * 0.5)}px;
  margin-bottom: ${({theme}) => theme.spacing(1)}px;
  padding-bottom: ${({theme}) => theme.spacing(1)}px;
  padding-left: ${({theme}) => theme.spacing(1)}px;
  padding-right: ${({theme}) => theme.spacing(1)}px;
  ${breakpoint.size('sm')`
    margin-top: ${({theme}) => theme.spacing(2 + 2.5 * 0.5)}px;
    padding-left: ${({theme}) => theme.spacing(2)}px;
    padding-right: ${({theme}) => theme.spacing(2)}px;
  `}
  ${breakpoint.size('md')`
    margin-top: ${({theme}) => theme.spacing(3 + 3.5 * 0.5)}px;
    margin-bottom: ${({theme}) => theme.spacing(1)}px;
    padding-bottom: ${({theme}) => theme.spacing(2)}px;
  `};
  width: 100%;
  background-color: ${({theme}) => theme.colors.white};
  background: linear-gradient(
    270deg,
    ${({theme}) => theme.colors.white} 70%,
    ${({theme}) => theme.colors.lightblue} 30%
  );
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  width: 100%;
  padding: 0;
  margin-top: -0.5em;
  margin-bottom: 0.5em;
  line-height: 1.1em;
  display: inline-block;
  font-size: ${({theme}) => theme.fontSize(1.75)}px;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(2.5)}px;
    margin-bottom: 0.75em;
  `}
  ${breakpoint.size('md')`
    font-size: ${({theme}) => theme.fontSize(3.5)}px;
    margin-bottom: 1em;
  `}
`;

const TextContent = styled.p`
  font-size: ${({theme}) => theme.fontSize(0.8)}px;
  line-height: 1.35em;
  margin-top: 1.75em;
  margin-bottom: 1.75em;
  max-width: 750px;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(1)}px;
  `}
`;

const Guest = styled.div`
  font-weight: 500;
  color: ${({theme}) => theme.colors.black};
  font-family: ${({theme}) => theme.fonts.teko};
  font-size: ${({theme}) => theme.fontSize(1.25)}px;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(1.55)}px;
  `}
`;

const ActionBar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  max-width: 100%;
  margin: ${({theme}) => theme.spacing(1)}px 0;
  ${breakpoint.size('sm')`
    margin: ${({theme}) => theme.spacing(1.5)}px 0;
    max-width: 25%;
  `}
`;

const ActionButton = styled(motion.button)`
  background: none;
  border: none;
  outline: none;
  color: ${({theme}) => theme.colors.blue};
  font-size: 2em;
  cursor: pointer;
  padding: 0;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const ReviewSlider: FC<SanityReviewSliderData> = props => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const prevReview = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex => activeIndex - 1);
    } else {
      setActiveIndex(props.reviews.length - 1);
    }
  };
  const nextReview = () => {
    if (activeIndex < props.reviews.length - 1) {
      setActiveIndex(activeIndex => activeIndex + 1);
    } else {
      setActiveIndex(0);
    }
  };
  return (
    <Wrapper>
      <Content>
        <Title>{props.title}</Title>
        <ActionBar>
          <ActionButton whileHover={{scale: 1.05}} whileTap={{scale: 0.95}} onClick={prevReview}>
            ←
          </ActionButton>
          <Spacer />
          <ActionButton whileHover={{scale: 1.05}} whileTap={{scale: 0.95}} onClick={nextReview}>
            →
          </ActionButton>
        </ActionBar>
        <Guest>{props.reviews[activeIndex].guest}</Guest>
        <TextContent>{props.reviews[activeIndex].content}</TextContent>
      </Content>
    </Wrapper>
  );
};

export default ReviewSlider;
